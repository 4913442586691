<template>
  <div class="uk-container uk-margin-medium-top">
    <div class="uk-card uk-card-default uk-card-body uk-margin">
      <article class="uk-comment">
        <header class="uk-comment-header">
          <div class="uk-grid-medium uk-flex-middle" uk-grid>
            <div class="uk-width-auto">
              <img
                class="uk-comment-avatar"
                :src="user.image"
                width="80"
                height="80"
                :alt="user.name"
              />
            </div>
            <div class="uk-width-expand">
              <h4 class="uk-comment-title uk-margin-small-bottom">
                <a class="uk-link-reset" href="#">{{ user.name }}</a>
              </h4>
              <ul
                class="
                  uk-comment-meta
                  uk-subnav
                  uk-subnav-divider
                  uk-margin-remove-top
                "
              >
              <template v-if="online">
                <li><a href="#">Online</a></li>
              </template>
                <template v-if="! online">
                  <li><a href="#">Last Seen</a></li>
                  <li><a href="#">{{ user.last_seen }}</a></li>
                </template>
              </ul>
            </div>
            <div>Joined: {{ user.joined }}</div>
          </div>
        </header>
      </article>
    </div>
    <div class="uk-grid">
      <div class="uk-width-1-2@m">
        <div class="uk-card uk-card-default uk-card-body">
          <h4>Full Profile</h4>
          <table class="uk-table uk-table-divider">
            <tbody>
              <tr>
                <td>Role</td>
                <td :class="`${loading ? 'skeleton skeleton-text' : ''} `">
                  {{ user.role }}
                </td>
              </tr>
              <tr v-if="user.role == 'guest' || user.role == 'trader'">
                <td>Courses Completed</td>
                <td :class="`${loading ? 'skeleton skeleton-text' : ''} `">
                  {{ user.courses_completed }}
                </td>
              </tr>
              <tr>
                <td>Level</td>
                <td :class="`${loading ? 'skeleton skeleton-text' : ''} `">
                  {{ user.level }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-width-1-2@m">
        <div class="uk-card uk-card-default uk-card-body">
          <p>Send a private message to {{ user.name }}</p>
          <form @submit.prevent="sendMessage">
            <div class="form-group">
              <label>Your Message</label>
              <textarea
                class="uk-textarea"
                rows="4"
                placeholder="Your Message"
                v-model="new_message.content"
              ></textarea>
            </div>
            <div class="form-group">
              <button class="uk-button uk-button-primary uk-margin-small-top">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      user: {},
      loading: true,
      show_send_message: false,
      new_message: {},
    };
  },

  methods: {
    sendMessage() {
      let data = {
        to: this.user.id,
        content: this.new_message.content,
        type: "text",
      };

      this.$axios.post("/api/v1/trader/messages", data).then(() => {
        this.show_send_message = false;
        this.$toasted.show("Your message has been sent", { duration: 5000 });
        this.new_message = {}
      });
    },

    fetch() {
      this.$axios
        .get(`/api/v1/trader/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
          this.loading = false;
        });
    },
  },

  computed: {
    online() {
      return this.$store.getters.getOnlineUsers.filter(user => {
        return this.user.id == user.id
      }).length > 0
    }
  }
};
</script>
